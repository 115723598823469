import React from 'react'
import Link from 'gatsby-link'
import Layout from '../components/Layout'
import thankyou from "../images/thankyou.png";
import { thankyou_text} from '../components/strings'
import AOS from 'aos';

class ThankyouPage extends React.Component{
    componentDidMount(){
        AOS.init({
          duration : 500
        })
      }
    render(){
        return(
  <Layout>
    <div data-aos='fade-zoom-in'>
      
        <section id="p_thankyou" className="hero is-fullheight pages">
    <div className="hero-head"></div>
        <div className="hero-body is-padding-0">
            <div className="overlay_image"></div>
            <div className="overlay_color"></div>
           <div className="image-box"> <img src={thankyou} alt=""/></div>
           <div className="title-box"><h2 className="title">{thankyou_text}<br /> <br /><Link to="/">Homepage</Link></h2></div>
            <div className="container">   
            </div>
        </div>
    <div className="hero-foot">
   
    </div>
</section>
    </div>
</Layout>
)
        }
    }

export default ThankyouPage

